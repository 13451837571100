import React, { CSSProperties } from 'react'
import { ButtonIcons } from '../../types'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import ArrowBackIosRounded from '@mui/icons-material/ArrowBackIosRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import ChecklistRoundedIcon from '@mui/icons-material/ChecklistRounded'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded'
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded'
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import CloseRounded from '@mui/icons-material/CloseRounded'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import SendRoundedIcon from '@mui/icons-material/SendRounded'
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded'
import { ExitToApp } from '@mui/icons-material'

type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'delete' | 'quatrinary'

interface BaseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean
  fullWidth?: boolean
  leftIcon?: ButtonIcons
  loading?: boolean
  onClick?: any
  rightIcon?: ButtonIcons
  rounded?: boolean
  text: string
  variant?: ButtonVariant
}

const BaseButton: React.FC<BaseButtonProps> = (props) => {
  const getIcon = (icon: string | undefined) => {
    if (props.loading) {
      return null
    }

    const style: CSSProperties = {
      fontSize: '1em',
    }

    switch (icon) {
      case 'add':
        return <AddCircleIcon style={style} />
      case 'arrowLeft':
        return <ArrowBackIosRounded style={style} />
      case 'arrowRight':
        return <ArrowForwardIosRoundedIcon style={style} />
      case 'checklist':
        return <ChecklistRoundedIcon style={style} />
      case 'edit':
        return <EditRoundedIcon style={style} />
      case 'exit':
        return <ExitToApp style={style} />
      case 'file':
        return <UploadFileRoundedIcon style={style} />
      case 'notification':
        return <NotificationsRoundedIcon style={style} />
      case 'notificationOff':
        return <NotificationsOffOutlinedIcon style={style} />
      case 'save':
        return <SaveRoundedIcon style={style} />
      case 'search':
        return <SearchRoundedIcon style={style} />
      case 'send':
        return <SendRoundedIcon style={style} />
      case 'suspend': 
        return <HourglassTopRoundedIcon style={style} />
      case 'trash':
        return <DeleteForeverRoundedIcon style={style} />
      case 'x':
        return <CloseRounded style={style} />
    }
  }

  const getBgColor = (): string => {
    if (props.disabled) {
      return 'var(--ui-mid-grey)'
    }

    if (props.loading) {
      return 'var(--ui-dark-grey)'
    }

    switch (props.variant) {
      case 'secondary':
        return 'var(--app-secondary)'
      case 'tertiary':
        return 'var(--app-tertiary)'
      case 'quatrinary':
        return 'var(--ui-blue)'
      case 'delete':
        return 'var(--ui-red)'
      default:
        return 'var(--app-primary)'
    }

  }

  return (
    <Button
      disabled={props.disabled || props.loading}
      fullWidth={props.fullWidth}
      onClick={props.onClick}
      style={{
        backgroundColor: getBgColor(),
        color: 'var(--app-text-light)',
        fontFamily: 'Avenir, Helvetica Neue, sans-serif',
        fontSize: 16,
        textTransform: 'none',
        borderRadius: props.rounded ? '2em' : '8px',
        height: props.style?.height || 40,
        ...props.style
      }}
      type={props.type || 'button'}
      variant='contained'
    >
      <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
        {props.leftIcon &&
          <div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }}>
            {getIcon(props.leftIcon)}
          </div>
        }
        <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
          {props.text}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 8 }}>
          {props.loading &&
            <CircularProgress size={20} color='primary' style={{ color: 'white' }} />
          }
          {props.rightIcon && getIcon(props.rightIcon)}
        </div>
      </div>
    </Button>
  )
}

export default BaseButton