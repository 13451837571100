import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Order } from '../../types'
import { RootState } from '..'

export interface OrderState {
    draft: Partial<Order> | null
    list: Order[]
}

const initialState: OrderState = {
    draft: null,
    list: []
}

//Create slice in App State
export const orderSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        resetDraftOrder: (state, action: PayloadAction<Partial<Order>>) => {
            state.draft = action.payload
        },
        resetOrdersSlice: () => initialState,
        setDraftOrder: (state, action: PayloadAction<Order | null>) => {
            state.draft = action.payload
        },
        setOrders: (state, action: PayloadAction<Order[]>) => {
            state.list = action.payload
        },
        updateDraftOrder: (state, action: PayloadAction<Partial<Order>>) => {
            state.draft = { ...state.draft, ...action.payload }
        }
    },
})

//Actions
export const { resetDraftOrder, resetOrdersSlice, setDraftOrder, setOrders, updateDraftOrder } = orderSlice.actions

//Selectors
export const getDraftOrder = (state: RootState) => state.orders.draft
export const getOrders = (state: RootState) => state.orders.list

//Export Reducer
export default orderSlice.reducer