
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import * as Store from '../../store/slices'
import { Row } from '../layout'
import { Notification } from '../../types'
import { ApiUtility, Endpoints, FormattingUtility } from '../../utilities'
import { IconButton, Typography } from '@mui/material'
import CloseRounded from '@mui/icons-material/CloseRounded'
import React from 'react'

export default function Item({ notification, showTimestamp = true }: { notification: Notification, showTimestamp: boolean }) {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const notifications = useAppSelector(Store.getNotifications)

    const notificationRead: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.stopPropagation()

        // 0. Mark this notification as read
        ApiUtility.Post(Endpoints.Notifications, { uuids: [notification.uuid] })
        // 0. Remove this notification from the local state
        dispatch(Store.setNotifications(notifications.filter(el => el.uuid !== notification.uuid)))
    }

    const onClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
        notificationRead(e as any)

        // 0. Navigate to the given url, if available
        if (notification.url) {
            // eslint-disable-next-line no-restricted-globals
            const originLength = location.origin.length
            const url = notification.url.substring(originLength)
            history.push(url)
        }
    }

    return (
        <div className='notification' onClick={onClick}>
            {showTimestamp
                ? (
                    <div style={{ padding: '2px 0px' }}>
                        <p className='table-label' style={{ display: 'inline-block' }}>{FormattingUtility.formatNotificationTimeStamp(notification.created)}</p>
                        <p className='table-data' style={{ display: 'inline-block' }}>{notification.title}</p>
                    </div>
                )
                : <Typography>{notification.title}</Typography>
            }
            <div className='x-container'>
                <IconButton className='x-btn' onClick={notificationRead}>
                    <CloseRounded className={'x-x'} style={{ fontSize: '0.6em' }} />
                </IconButton>
            </div>
        </div>
    )
}