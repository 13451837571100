import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded'
import { Stack } from '@mui/material'
import { DragDropContext, Droppable, Draggable, OnDragEndResponder, DraggingStyle } from 'react-beautiful-dnd'
import { LineItem, Room } from '../../types'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import * as Store from '../../store/slices'
import { FormattingUtility } from '../../utilities'



const DraggableLineItem = ({ allowEdits, lineItem }: { allowEdits: boolean, lineItem: LineItem }) => {
    return (
        <Stack spacing={1} direction='row' alignItems='center'>
            {allowEdits && (
                <DragIndicatorRoundedIcon fontSize='small' />
            )}
            <p style={{ fontFamily: 'monospace', fontStyle: 'italic' }}>{lineItem.itemNumber} - {lineItem.sku || '[NO SKU]'}: {lineItem.description}</p>
        </Stack>
    )
}

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: 'none',
    padding: '8px 0px',
    borderRadius: '4px',
    background: isDragging ? 'var(--ui-lightgrey)' : 'white',
    ...draggableStyle
})

const RoomsReview = ({ allowEdits, rooms }: { allowEdits: boolean, rooms: Room[] }) => {
    const dispatch = useAppDispatch()
    const draftOrder = useAppSelector(Store.getDraftOrder)

    const reorder = (list: LineItem[], startIndex: number, endIndex: number) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    };

    const handleRoomSubmit = (room: Room) => {
        if (!draftOrder) {
            return
        }
        let rooms = FormattingUtility.updateRoomWithinOrderRooms(draftOrder, room)
        dispatch(Store.updateDraftOrder({ rooms }))
    }
    const onDragEnd = (result: any, room: Room) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        const lineItems = reorder(
            room.lineItems,
            result.source.index,
            result.destination.index
        )

        let rooms = FormattingUtility.updateRoomWithinOrderRooms(draftOrder, {...room, lineItems})
        dispatch(Store.updateDraftOrder({rooms}))
    }

    return (
        !!rooms && rooms.length > 0
            ? (
                <div style={{ overflow: 'scroll', maxHeight: '100%' }}>
                    {rooms.map((room, roomIndex) => (
                        <div key={room.key} style={{ borderBottomColor: 'lightgrey', borderBottomStyle: 'solid', borderBottomWidth: (roomIndex < rooms.length - 1) ? '1px' : 0, paddingBottom: '8px', marginBottom: '4px' }}>
                            <h4 style={{ fontSize: '1em', color: 'var(--app-primary)' }}>{room.name}</h4>
                            <DragDropContext onDragEnd={(result) => onDragEnd(result, room)}>
                                <Droppable droppableId='lineItems-dnd'>
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {room.lineItems && room.lineItems.map((lineItem, index) => (
                                                <Draggable key={lineItem.key} draggableId={lineItem.key} index={index} isDragDisabled={!allowEdits}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            <DraggableLineItem lineItem={lineItem} allowEdits={allowEdits} />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    ))}
                </div>
            )
            : (
                <div><i>There is no room or cabinet information available for this order.</i></div>
            )
    )
}

export default RoomsReview