import { ChangeEvent, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import * as Store from '../store/slices'
import { BaseButton } from '../components/buttons'
import { ChangeEmailDialog, ChangePasswordDialog, ChangePhoneDialog } from '../components/dialogs'
import { BaseInput, PhoneInput } from '../components/inputs'
import { BasePage, Card } from '../components/layout'
import { AutoLoadImage } from '../components/ui'
import { User } from '../types'
import { ApiUtility, Endpoints, FormattingUtility, StorageUtility } from '../utilities'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { v4 } from 'uuid'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'

const FormSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
})

export default function Page() {
    const dispatch = useAppDispatch()
    const currentUser = useAppSelector(Store.getCurrentUser)!
    const [isChangePwOpen, toggleIsChangePwOpen] = useState<boolean>(false)
    const [isChangeEmailOpen, toggleIsChangeEmailOpen] = useState<boolean>(false)
    const [isChangePhoneOpen, toggleIsChangePhoneOpen] = useState<boolean>(false)
    const [isProfileLoading, toggleIsProfileLoading] = useState<boolean>(false)
    const inputFile = useRef<HTMLInputElement>(null)

    const updateProfile = async (values: any) => {
        const response = await ApiUtility.Post(Endpoints.Profile, values)
        if (!response.success) {
            alert('Not success')
            return
        }

        dispatch(Store.setCurrentUser(response.data as User))

        alert('Success!')
    }

    const openFilePicker = () => {
        if (!inputFile || !inputFile.current) {
            return
        }

        inputFile.current.click()
    }

    const verifyAndUploadFile = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!inputFile || !inputFile.current || !event.target.files || event.target.files.length !== 1) {
            return
        }

        toggleIsProfileLoading(true)

        const file = event.target.files[0]

        const ext = file.name.substring(file.name.lastIndexOf('.'))
        const newName = (v4() + ext).toLowerCase()

        const uploadResult = await StorageUtility.UploadObject(file, 'profiles', newName)
        inputFile.current.value = ''

        if (!uploadResult.url) {
            toggleIsProfileLoading(false)
            alert('There was an error while uploading your profile picture. Please try again.')
            return
        }

        const body = {
            profileImg: uploadResult.url,
        }

        const response = await ApiUtility.Post(Endpoints.Profile, body)

        if (!response.success) {
            toggleIsProfileLoading(false)
            alert('There was an error while updating your profile picture. Please try again.')
            return
        }

        // Update profile locally
        dispatch(Store.setCurrentUser(response.data))

        toggleIsProfileLoading(false)
    }

    return (
        <BasePage title='Profile' requireAuth={true}>
            <div className='ui-row grid-container'>
                <div className='grid'>
                    <Formik
                        initialValues={{
                            firstName: currentUser.firstName,
                            lastName: currentUser.lastName,
                        }}
                        validationSchema={FormSchema}
                        onSubmit={updateProfile}
                    >
                        {({ values, errors, touched, dirty, isValid, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
                            <Card title='Account'>
                                <div className='row' style={{ width: '100%' }}>
                                    <div className='column flex-center'>
                                        <input type='file' accept='.jpg,.jpeg,.png' multiple={false} name='file' ref={inputFile} style={{ display: 'none' }} onChange={verifyAndUploadFile} />
                                        {!!currentUser.profileImg
                                            ? <AutoLoadImage src={currentUser.profileImg} size='4em' loading={isProfileLoading} />
                                            : <PersonRoundedIcon style={{ borderRadius: '2em', border: '1px dashed var(--ui-grey)', color: 'var(--ui-grey)', height: '3em', width: '3em' }} />
                                        }
                                        <p style={{ fontSize: '8px', cursor: 'pointer', marginTop: '1em' }} onClick={openFilePicker} >{!!currentUser.profileImg ? 'Change': 'Upload'}</p>
                                    </div>
                                    <div className='column' style={{ marginLeft: '1em', flex: 1 }}>
                                        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                            <BaseInput name='firstName' label='First Name' defaultValue={values.firstName} error={touched.firstName && errors.firstName ? errors.firstName : ''} fullWidth onBlur={handleBlur} onChange={handleChange} />
                                            <BaseInput name='lastName' label='Last Name' defaultValue={values.lastName} error={touched.lastName && errors.lastName ? errors.lastName : ''} fullWidth onBlur={handleBlur} onChange={handleChange} />
                                            <BaseButton text='Update' disabled={!dirty || !isValid} onClick={handleSubmit} type='submit' rightIcon='arrowRight' loading={isSubmitting} />
                                        </form>
                                    </div>
                                </div>
                            </Card>
                        )}
                    </Formik>
                    <Card title='Security'>
                        <BaseInput label='Email' value={currentUser.email} fullWidth disabled onChange={() => { }} />
                        <PhoneInput label='Cell Phone' defaultValue={currentUser.phoneNumber} fullWidth disabled onChange={() => { }} />
                        <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '1em' }}>
                            <BaseButton text='Change Password' variant='tertiary' fullWidth={false} rightIcon='arrowRight' onClick={() => toggleIsChangePwOpen(true)} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '1em' }}>
                            <BaseButton text='Change Email' variant='tertiary' fullWidth={false} rightIcon='arrowRight' onClick={() => toggleIsChangeEmailOpen(true)} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <BaseButton text='Change Cell Phone' variant='tertiary' fullWidth={false} rightIcon='arrowRight' onClick={() => toggleIsChangePhoneOpen(true)} />
                        </div>
                    </Card>
                </div>
            </div>
            <div className='ui-row column'>
                <p className='subtle'>Account Created: {FormattingUtility.formatISODate(currentUser.created, 't ZZZZ, MMMM d, yyyy')}</p>
                <p className='subtle'>Last Updated: {FormattingUtility.formatISODate(currentUser.modified, 't ZZZZ, MMMM d, yyyy')}</p>
            </div>
            <ChangePasswordDialog open={isChangePwOpen} onCancel={() => toggleIsChangePwOpen(false)} onConfirm={() => toggleIsChangePwOpen(false)} />
            <ChangeEmailDialog open={isChangeEmailOpen} onClose={() => toggleIsChangeEmailOpen(false)} />
            <ChangePhoneDialog open={isChangePhoneOpen} onClose={() => toggleIsChangePhoneOpen(false)} />
        </BasePage>
    )
}