import { BaseButton, RoomChip } from '../buttons'
import { BaseInput, Checkbox, Dropdown } from '../inputs'
import { Card } from '../layout'
import { Order, Room } from '../../types'
import { ApiUtility, Endpoints, FormattingUtility } from '../../utilities'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { OrderStatus, UserRoles } from '../../types/enums'
import { Box, Chip, Card as MuiCard, Stack } from '@mui/material'
import RoomForm from './RoomForm'
import { useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import * as Store from '../../store/slices'

type EditOrderFormProps = {
    onCancel: () => void
    onSubmit: (order: Order) => void
}

const FormSchema = Yup.object().shape({
    jobNumber: Yup.string().required('Required'),
    jobName: Yup.string().required('Required'),
    purchaseOrder: Yup.string().nullable(),
    status: Yup.string().required('Required'),
    deliveryAddress1: Yup.string().required('Required'),
    deliveryAddress2: Yup.string().nullable(),
    deliveryCity: Yup.string().required('Required'),
    deliveryDate: Yup.string().nullable(),
    deliveryState: Yup.string().required('Required'),
    deliveryZip: Yup.string().required('Required'),
    deliveryContact: Yup.string().required('Required'),
    deliveryPhone: Yup.string().matches(FormattingUtility.PhoneNumberRegex, 'Invalid').required('Required'),
    deliveryEmail: Yup.string().required('Required'),
    salePrice: Yup.string().nullable(),
    notes: Yup.string().nullable(),
    shippingCost: Yup.string().nullable(),
    total: Yup.string().nullable(),
})

const defaultRoomKey = (order: Partial<Order> | null): string => {
    if (!order || !order.rooms || order.rooms.length < 1) {
        return ''
    }

    return order.rooms[0].key
}

export default function Form({ onCancel, onSubmit }: EditOrderFormProps) {
    const dispatch = useAppDispatch()
    const currentUser = useAppSelector(Store.getCurrentUser)
    const draftOrder = useAppSelector(Store.getDraftOrder)
    const [currentRoom, setCurrentRoom] = useState<string>(defaultRoomKey(draftOrder))
    const restrictedOrderFlow = useMemo(() => {
        return !!currentUser && [UserRoles.Dealer, UserRoles.Designer, UserRoles.LocationBillingContact, UserRoles.LocationManager].includes(currentUser.role)
    }, [currentUser?.role])
    const [createEstimate, setCreateEstimate] = useState<boolean>(true)

    const dispatchDraftOrderUpdate = (field: keyof Order, value: string | number) => {
        dispatch(Store.updateDraftOrder({[field]: value}))
    }

    const updateOrder = async () => {
        const response = await ApiUtility.Post(Endpoints.Orders, { ...draftOrder, createEstimate })

        if (!response.success) {
            alert(response.statusCode === 400 ? response.error.message : 'There was an error while trying to update this order.')
            return
        }

        onSubmit(response.data)
    }

    const addNewRoomToDraftOrder = () => {
        const newRoom = FormattingUtility.getEmptyRoom()
        let list: Room[] = JSON.parse(JSON.stringify(draftOrder!.rooms)) || []
        list.push(newRoom)
        dispatch(Store.updateDraftOrder({ rooms: list }))
        setCurrentRoom(newRoom.key)
    }

    const deleteRoomFromDraftOrder = () => {
        let list: Room[] = JSON.parse(JSON.stringify(draftOrder!.rooms)) || []

        // Handle cases where we need a fresh list of rooms on the draft order
        if (list.length <= 1) {
            const newRoom = FormattingUtility.getEmptyRoom()
            dispatch(Store.updateDraftOrder({ rooms: [newRoom] }))
            setCurrentRoom(newRoom.key)
            return
        }

        // Remove the currently selected room and set the new current room to the first remaining room on the order
        list = list.filter(room => room.key != currentRoom)
        // TODO: Update line item numbers
        dispatch(Store.updateDraftOrder({ rooms: list }))
        setCurrentRoom(list[0].key)
    }

    return (
        <Formik
            initialValues={draftOrder as Order}
            onSubmit={updateOrder}
            validationSchema={FormSchema}
        >
            {({ values, errors, touched, isValid, handleBlur, handleChange, handleSubmit, isSubmitting, setValues }) => (
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <div className='ui-row' style={{ justifyContent: 'space-between' }}>
                        <p className='wizard-instructions'>Dealership information, files, and messages may not be edited.</p>
                        <Stack spacing={1} justifyContent='flex-end'>
                            <Stack spacing={1} direction='row' justifyContent='flex-end'>
                                <BaseButton text='Cancel' variant='delete' leftIcon='x' onClick={onCancel} disabled={isSubmitting} />
                                <BaseButton text='Submit' rightIcon='arrowRight' type='submit' loading={isSubmitting} />
                            </Stack>
                            {currentUser?.role === UserRoles.Admin && (
                                <MuiCard style={{ border: '1px solid var(--ui-light-blue)', boxShadow: '1px 1px 5px 1px #d1d1d1', borderRadius: '1em', padding: '0.5em', outline: 'none' }}>
                                    <Checkbox label='Create/Update Estimate PDF' checked={createEstimate} onChange={() => setCreateEstimate(!createEstimate)} />
                                </MuiCard>
                            )}
                        </Stack>
                    </div>
                    <div className='ui-row' style={{ width: '100%' }}>
                        <div className='column' style={{ width: '100%' }}>
                            <div className='grid-container'>
                                <div className='grid'>
                                    <Card title='Admin'>
                                        <BaseInput
                                            name='jobNumber' 
                                            label='Job Number' 
                                            defaultValue={values.jobNumber} 
                                            error={touched.jobNumber && errors.jobNumber ? errors.jobNumber : ''} 
                                            fullWidth={true} 
                                            onBlur={handleBlur} 
                                            onChange={(e) => {handleChange(e); dispatchDraftOrderUpdate('jobNumber', e.target.value)}} 
                                        />
                                        <BaseInput 
                                            name='jobName' 
                                            label='Job Name'
                                            defaultValue={values.jobName} 
                                            error={touched.jobName && errors.jobName ? errors.jobName : ''} 
                                            fullWidth={true} 
                                            onBlur={handleBlur} 
                                            onChange={(e) => {handleChange(e); dispatchDraftOrderUpdate('jobName', e.target.value)}} 
                                        />
                                        <BaseInput name='purchaseOrder' label='PO Number' optional defaultValue={values.purchaseOrder} error={touched.purchaseOrder && errors.purchaseOrder ? errors.purchaseOrder : ''} fullWidth={true} onBlur={handleBlur} onChange={(e) => {handleChange(e); dispatchDraftOrderUpdate('purchaseOrder', e.target.value)}} />
                                        {!restrictedOrderFlow && (
                                            <Dropdown
                                                defaultValue={FormattingUtility.getOrderStatus(values.status)}
                                                label='Status'
                                                items={FormattingUtility.OrderStatuses_DropdownList}
                                                fullWidth
                                                onSelect={(selected, item) => {setValues({ ...values, status: item.key as OrderStatus }); dispatchDraftOrderUpdate('status', item.key)}}
                                            />
                                        )}
                                        {!restrictedOrderFlow && (
                                            <BaseInput name='notes' label='Notes' type='textarea' optional defaultValue={values.notes} error={touched.salePrice && errors.salePrice ? errors.salePrice : ''} fullWidth={true} onBlur={handleBlur} onChange={(e) => {handleChange(e); dispatchDraftOrderUpdate('notes', e.target.value)}} />
                                        )}
                                    </Card>
                                    {!restrictedOrderFlow && (
                                        <Card title='Billing'>
                                            <BaseInput name='salePrice' label='Cost' optional defaultValue={values.salePrice} error={touched.salePrice && errors.salePrice ? errors.salePrice : ''} fullWidth={true} onBlur={handleBlur} onChange={(e) => {handleChange(e); dispatchDraftOrderUpdate('salePrice', e.target.value)}} />
                                            <BaseInput name='shippingCost' label='Shipping Cost' optional defaultValue={values.shippingCost} error={touched.shippingCost && errors.shippingCost ? errors.shippingCost : ''} fullWidth={true} onBlur={handleBlur} onChange={(e) => {handleChange(e); dispatchDraftOrderUpdate('shippingCost', e.target.value)}} />
                                            <BaseInput name='total' label='Total' optional defaultValue={values.total?.toString()} error={touched.total && errors.total ? errors.total : ''} fullWidth={true} onBlur={handleBlur} onChange={(e) => {handleChange(e); dispatchDraftOrderUpdate('total', e.target.value)}} />
                                        </Card>
                                    )}
                                    <Card title='Delivery'>
                                        {!restrictedOrderFlow && (
                                            <BaseInput name='deliveryDate' label='Delivery Date' defaultValue={values.deliveryDate} optional error={touched.deliveryDate && errors.deliveryDate ? errors.deliveryDate : ''} type='date' fullWidth={true} onBlur={handleBlur} onChange={(e) => {handleChange(e); dispatchDraftOrderUpdate('deliveryDate', e.target.value)}} />
                                        )}
                                        <BaseInput name='deliveryContact' label='Contact' defaultValue={values.deliveryContact} error={touched.deliveryContact && errors.deliveryContact ? errors.deliveryContact : ''} fullWidth={true} onBlur={handleBlur} onChange={(e) => {handleChange(e); dispatchDraftOrderUpdate('deliveryContact', e.target.value)}} />
                                        <BaseInput name='deliveryPhone' label='Phone Number' defaultValue={values.deliveryPhone} error={touched.deliveryPhone && errors.deliveryPhone ? errors.deliveryPhone : ''} fullWidth={true} onBlur={handleBlur} onChange={(e) => {handleChange(e); dispatchDraftOrderUpdate('deliveryPhone', e.target.value)}} />
                                        <BaseInput name='deliveryEmail' label='Email' defaultValue={values.deliveryEmail} error={touched.deliveryEmail && errors.deliveryEmail ? errors.deliveryEmail : ''} fullWidth={true} onBlur={handleBlur} onChange={(e) => {handleChange(e); dispatchDraftOrderUpdate('deliveryEmail', e.target.value)}} />
                                    </Card>
                                    <Card title='Address'>
                                        <BaseInput name='deliveryAddress1' label='Address 1' defaultValue={values.deliveryAddress1} error={touched.deliveryAddress1 && errors.deliveryAddress1 ? errors.deliveryAddress1 : ''} fullWidth={true} onBlur={handleBlur} onChange={(e) => {handleChange(e); dispatchDraftOrderUpdate('deliveryAddress1', e.target.value)}} />
                                        <BaseInput name='deliveryAddress2' label='Address 2' optional defaultValue={values.deliveryAddress2} error={touched.deliveryAddress2 && errors.deliveryAddress2 ? errors.deliveryAddress2 : ''} fullWidth={true} onBlur={handleBlur} onChange={(e) => {handleChange(e); dispatchDraftOrderUpdate('deliveryAddress2', e.target.value)}} />
                                        <BaseInput name='deliveryCity' label='City' defaultValue={values.deliveryCity} error={touched.deliveryCity && errors.deliveryCity ? errors.deliveryCity : ''} fullWidth={true} onBlur={handleBlur} onChange={(e) => {handleChange(e); dispatchDraftOrderUpdate('deliveryCity', e.target.value)}} />
                                        <BaseInput name='deliveryState' label='State' defaultValue={values.deliveryState} error={touched.deliveryState && errors.deliveryState ? errors.deliveryState : ''} fullWidth={true} onBlur={handleBlur} onChange={(e) => {handleChange(e); dispatchDraftOrderUpdate('deliveryState', e.target.value)}} />
                                        <BaseInput name='deliveryZip' label='Zip' defaultValue={values.deliveryZip} error={touched.deliveryZip && errors.deliveryZip ? errors.deliveryZip : ''} fullWidth={true} onBlur={handleBlur} onChange={(e) => {handleChange(e); dispatchDraftOrderUpdate('deliveryZip', e.target.value)}} />
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!!draftOrder && draftOrder.rooms && draftOrder.rooms?.length > 0
                        ? (
                            <div className='ui-row' style={{marginBottom: '3em'}}>
                                <Card
                                    title='Rooms'
                                    fullWidth
                                    maxHeight='unset'
                                    rightHeader={
                                        <Stack direction='row' spacing={1}>
                                            {!!draftOrder.rooms && draftOrder.rooms?.length > 0 && draftOrder.rooms.map((room) => (
                                                <RoomChip key={room.key} room={room} onClick={(room) => setCurrentRoom(room.key)} selected={room.key === currentRoom} />
                                            ))}
                                        </Stack>
                                    }
                                >
                                    <RoomForm
                                        deleteRoom={deleteRoomFromDraftOrder}
                                        mode='edit'
                                        onRoomAdded={addNewRoomToDraftOrder}
                                        onSubmitUpdates={updateOrder}
                                        room={draftOrder?.rooms?.find(el => el.key === currentRoom)}
                                    />
                                </Card>
                            </div>
                        )
                        : (
                            <div className='ui-row'>
                                <Card
                                    title='Rooms'
                                    error={true}
                                    fullWidth
                                    maxHeight='800px'
                                    rightHeader={
                                        <BaseButton text='ADD ROOM' rightIcon='add' variant='secondary' onClick={addNewRoomToDraftOrder} />
                                    }
                                >
                                    <i>There is no room or cabinet information available for this order.</i>
                                </Card>
                            </div>
                        )
                    }
                </form>
            )}
        </Formik>
    )
}