import { MouseEventHandler, useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { StorageUtility } from '../../utilities'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
interface AutoLoadImageProps {
    fallbackImg?: string
    loading?: boolean
    onClick?: MouseEventHandler
    size: string 
    src: string | undefined
}

export default function AutoLoadImage(props: AutoLoadImageProps) {
    const [isLoading, setLoading] = useState<boolean>(props.loading || true)
    const [signedUrl, setSignedUrl] = useState<string | null>(null)

    const signUrl = async () => {
        if (!props.src || props.src === '') {
            setLoading(false)
            return
        }

        let url = await StorageUtility.GetSignedUrl(props.src)

        setSignedUrl(url)
        setLoading(false)
    }

    useEffect(() => {
        signUrl()
    }, [props.src])

    return (
        <div 
            onClick={props.onClick} 
            style={{ 
                height: props.size, 
                width: props.size, 
                borderRadius: '50px', 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center', 
                alignItems: 'center', 
                overflow: 'hidden',
                cursor: props.onClick ? 'pointer' : 'default',
                border: !isLoading && !signedUrl ? '1px dashed var(--ui-grey)' : '',
            }}
        >
            {isLoading &&
                <CircularProgress size={props.size} style={{ color: 'var(--app-secondary)' }} />
            }
            {!isLoading && !signedUrl && (
                <PersonRoundedIcon style={{ color: 'var(--ui-grey)' }} />
            )}
            <img 
                style={{ width: '100%' }}
                onError={() => {setLoading(false); setSignedUrl(null); }}
                onLoadedData={() => setLoading(false)}
                src={signedUrl || props.fallbackImg}
                alt='' 
            />
        </div>
    )
}