import { ReactNode, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import * as Store from '../../store/slices'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { urls } from '../../pages'
import { BaseButton, ChipButton } from '../buttons'
import Popover from './Popover'
import { AutoLoadImage, Notification } from '../ui'
import { ApiUtility, Endpoints } from '../../utilities'
import { Box, CircularProgress, Stack } from '@mui/material'
import { UserRoles } from '../../types/enums'

enum WidgetMenus {
    Notifications,
    Profile,
}

export default function UserWidget() {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const currentUser = useAppSelector(Store.getCurrentUser)
    const notifications = useAppSelector(Store.getNotifications)
    const currentThread = useAppSelector(Store.getCurrentThread)
    const [anchor, setAnchor] = useState<HTMLDivElement | null>(null)
    const [menu, setMenu] = useState<WidgetMenus>()

    const navigate = (url: string) => {
        if (history.location.pathname === url) {
            return
        }

        history.push(url)
        setAnchor(null)
    }

    const clearAllNotifications = async () => {
        const body = {
            uuids: notifications.map(el => el.uuid)
        }

        const response = await ApiUtility.Post(Endpoints.Notifications, body)
        if (response.success) {
            dispatch(Store.setNotifications([]))
        }
    }

    const renderMenu = (): ReactNode => {
        if (!currentUser) {
            return null
        }

        switch (menu) {
            case WidgetMenus.Notifications:
                return (
                    <div className='widget-menu noselect'>
                        <div className='row' style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <h2 style={{ textAlign: 'center' }}>Notifications</h2>
                            {notifications.length > 0 && (
                                <ChipButton text='Clear all' onClick={clearAllNotifications} />
                            )}
                        </div>
                        <div style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
                            {notifications.length > 0 && notifications.map(el => (
                                <Notification key={el.uuid} notification={el} showTimestamp={true} />
                            ))}
                            {notifications.length < 1 && (
                                <p className='subtle'>There are currently no unread notifications.</p>
                            )}
                        </div>
                    </div>
                )
            case WidgetMenus.Profile:
                return (
                    <div className='widget-menu noselect' style={{ maxWidth: '300px' }}>
                        <Stack spacing={2}>
                            <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
                                <AutoLoadImage src={currentUser?.profileImg} size='3em' />
                                <h2 style={{ marginLeft: '1em' }}>{currentUser.firstName + ' ' + currentUser.lastName}</h2>
                            </Stack>
                            <Box>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <b>Notifications</b>
                                    {notifications.length > 0 && (
                                        <ChipButton text='Clear all' onClick={clearAllNotifications} />
                                    )}
                                </Box>
                                <div style={{ maxHeight: '8em', overflowY: 'scroll' }}>
                                    {notifications.length > 0 && notifications.map(el => (
                                        <Notification key={el.uuid} notification={el} showTimestamp={false} />
                                    ))}
                                    {notifications.length < 1 && (
                                        <p className='subtle'>There are currently no unread notifications.</p>
                                    )}
                                </div>
                            </Box>
                            <BaseButton rightIcon='arrowRight' variant='tertiary' text='Profile' onClick={() => navigate(urls.profile)} />
                            <BaseButton rightIcon='exit' text='Logout' onClick={() => navigate(urls.landing)} />
                        </Stack>
                    </div>
                )
        }
    }

    const createInterval = (fetchFn: () => void, ms: number) => {
        return window.setInterval(() => {
            fetchFn()
        }, ms)
    }

    const fetchDealerships = async () => {
        let response = await ApiUtility.Get(Endpoints.Dealerships)
        if (response.success) {
            dispatch(Store.setDealerships(response.data))
        }
    }

    const fetchNotifications = async () => {
        let response = await ApiUtility.Get(Endpoints.Notifications)
        if (response.success) {
            // NOTE: Check for an active message drawer.
            if (!!currentThread) {
                // TODO: If new notifications are for the active thread, mark them as read.
                // NOTE: For now, do nothing with this.
            }
            dispatch(Store.setNotifications(response.data))
        }
    }

    const fetchOrders = async () => {
        let response = await ApiUtility.Get(Endpoints.Orders)
        if (response.success) {
            dispatch(Store.setOrders(response.data))
        }
    }

    const fetchThreads = async () => {
        let response = await ApiUtility.Get(Endpoints.MessageThreads)
        if (response.success) {
            dispatch(Store.setThreads(response.data))
        }
    }

    useEffect(() => {
        if (!currentUser) {
            return
        }

        const ordersInterval = createInterval(fetchOrders, 5000)
        const notificationsInterval = createInterval(fetchNotifications, 5000)
        const threadsInterval = createInterval(fetchThreads, 5000)

        let dealershipsInterval = -1
        if (currentUser.role === UserRoles.Admin) {
            dealershipsInterval = createInterval(fetchDealerships, 30000)
        }

        return () => {
            window.clearInterval(ordersInterval)
            window.clearInterval(notificationsInterval)
            window.clearInterval(threadsInterval)
            window.clearInterval(dealershipsInterval)
        }
    }, [currentUser, currentThread])

    return (
        <>
            <div className='user-widget' tabIndex={0} onKeyDown={e => { setAnchor(e.currentTarget); setMenu(WidgetMenus.Profile) }} onClick={(e) => { setAnchor(e.currentTarget); setMenu(WidgetMenus.Profile) }}>
                <div className='widget-icon' style={{ opacity: currentUser?.profileImg ? 'unset' : 'initial', borderRadius: '2em' }}>
                    <AutoLoadImage src={currentUser?.profileImg} size='2em' />
                </div>
                <p style={{ marginLeft: '0.5em', fontWeight: '500' }}>{currentUser ? currentUser.firstName + ' ' + currentUser.lastName : ''}</p>
            </div>

            <Popover
                anchor={anchor}
                onClickAway={() => setAnchor(null)}
                style={{ zIndex: '4' }}
            >
                {renderMenu()}
            </Popover>
        </>
    )
}