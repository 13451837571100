import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Notification } from '../../types'
import { RootState } from '..'

export interface NotificationState {
    list: Notification[]
}

const initialState: NotificationState = {
    list: []
}

//Create slice in App State
export const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        resetNotificationsSlice: () => initialState,
        setNotifications: (state, action: PayloadAction<Notification[]>) => {
            state.list = action.payload
        },
    },
})

//Actions
export const { setNotifications, resetNotificationsSlice } = notificationSlice.actions

//Selectors
export const getNotifications = (state: RootState) => state.notifications.list

//Export Reducer
export default notificationSlice.reducer