import { BaseButton } from '../buttons'
import { CabinetForm, RoomsReview } from './'
import { FloatingActionBar } from '../ui'
import { Formik } from 'formik'
import { BaseInput, ComboBox } from '../inputs'
import { ChevronRightRounded } from '@mui/icons-material'
import { Box, Divider, Drawer, IconButton, Stack } from '@mui/material'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import * as Store from '../../store/slices'
import { LineItem, Order, Room } from '../../types'
import Data from '../../utilities/data.json'
import { v4 } from 'uuid'
import { ApiUtility, Endpoints, FormattingUtility } from '../../utilities'

enum UIState {
    RoomInfo = 1,
    Cabinets = 2,
}

type RoomFormProps = {
    deleteRoom: () => void
    mode: 'create' | 'edit'
    onSubmitUpdates?: () => Promise<void>
    onRoomAdded: () => void
    room?: Room
}

const CopyStyleButton = ({ onClick }: { onClick: () => {} }) => {
    return (
        <IconButton size='small' onClick={onClick} style={{ backgroundColor: '#eee', borderRadius: '32px', height: '32px' }}>
            <ChevronRightRounded style={{ color: 'var(--app-secondary' }} />
        </IconButton>
    )
}

export default function RoomForm(props: RoomFormProps) {
    const dispatch = useAppDispatch()
    const draftOrder = useAppSelector(Store.getDraftOrder)
    const [state, setState] = useState<UIState>(UIState.RoomInfo)
    const [isDetailDrawerOpen, setDetailDrawerOpen] = useState<boolean>(false)
    const [isLoading, setLoading] = useState<boolean>(false)

    const newCabinet = (): Partial<LineItem> => {
        let roomIndex = draftOrder?.rooms?.findIndex(room => room.key === props.room?.key)
        let roomNumber = 1 + (roomIndex || 0)
        let newNumber = props.room?.lineItems && props.room.lineItems.length > 0 ? props.room.lineItems.length + 1 : 1
        return {
            key: v4(),
            itemNumber: roomNumber.toString() + newNumber.toString().padStart(2, '0')
        }
    }

    const dispatchRoomUpdate = (vals: any): Room[] => {
        let updatedRoom = { ...props.room, ...vals }
        let rooms = FormattingUtility.updateRoomWithinOrderRooms(draftOrder, updatedRoom as Room)
        dispatch(Store.updateDraftOrder({ rooms }))
        return rooms
    }

    const syncDraftOrderWithServer = async () => {
        const body = { ...draftOrder, userNotifications: false, systemMessages: false, createEstimate: false }
        let response: any = {}
        if (props.mode === 'create' && !body.idOrders) {
            response = await ApiUtility.Put(Endpoints.Orders, body)
        } else if (props.mode === 'edit' || (props.mode === 'create' && body.idOrders)) {
            response = await ApiUtility.Post(Endpoints.Orders, body)
        }

        if (response.success) {
            if (!body.idOrders) {
                let order: Order = response.data
                dispatch(Store.updateDraftOrder(order))
                // dispatch(Store.updateDraftOrder({
                //     ...draftOrder, 
                //     created: order.created, 
                //     createdBy: order.createdBy, 
                //     modified: order.modified, 
                //     modifiedBy: order.modifiedBy, 
                //     jobNumber: order.jobNumber
                // }))
            }
        } else {
            alert(response.statusCode === 400 ? response.error.message : `There was an error while trying to ${props.mode} this order.`)
            return
        }
    }

    const submitOrderUpdates = async () => {
        if (!props.onSubmitUpdates) {
            return
        }
        setLoading(true)
        await props.onSubmitUpdates()
        const response = await ApiUtility.Post(Endpoints.Orders, draftOrder)

        if (!response.success) {
            alert(response.statusCode === 400 ? response.error.message : 'There was an error while trying to update this order.')
        }
        setLoading(false)
    }

    return (
        <div style={{ overflowY: 'scroll' }}>
            <Formik
                initialValues={{ ...props.room }}
                enableReinitialize={true}
                onSubmit={() => { }}
            >
                {({ values, touched, errors, dirty, isSubmitting, isValid, handleSubmit, handleBlur, handleChange, setValues }) => (
                    <form onSubmit={handleSubmit}>
                        {state === UIState.RoomInfo && (
                            <>
                                <Stack spacing={2} direction='row' justifyContent='space-between' flexWrap='wrap'>
                                    <Stack spacing={1} style={{ flex: 1, minWidth: 300 }}>
                                        <BaseInput
                                            name='name'
                                            label='Room Name'
                                            value={values.name}
                                            error={touched.name && errors.name ? errors.name : ''}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                dispatchRoomUpdate({ name: e.target.value })
                                            }}
                                            fullWidth={true}
                                        />
                                    </Stack>
                                    <Stack spacing={1} style={{ flex: 1, minWidth: 300 }}>
                                        <ComboBox
                                            defaultValue={values.construction || ''}
                                            fullWidth
                                            items={Data.OrderDetails_Construction}
                                            label='Construction'
                                            name='construction'
                                            onSelect={(item) => {
                                                dispatchRoomUpdate({construction: item?.label})
                                            }}
                                        />
                                        <ComboBox
                                            defaultValue={values.wood || ''}
                                            fullWidth
                                            items={Data.OrderDetails_Wood}
                                            label='Wood'
                                            name='wood'
                                            onSelect={(item) => {
                                                dispatchRoomUpdate({ wood: item?.label })
                                            }}
                                        />
                                        <ComboBox
                                            defaultValue={values.interiorFinish || ''}
                                            fullWidth
                                            items={Data.OrderDetails_InteriorFinish}
                                            label='Interior Finish'
                                            name='interiorFinish'
                                            onSelect={(item) => {
                                                dispatchRoomUpdate({ interiorFinish: item?.label })
                                            }}
                                        />
                                    </Stack>
                                    <Stack spacing={1} style={{ flex: 1, minWidth: 300 }}>
                                        <ComboBox
                                            defaultValue={values.exteriorFinish || ''}
                                            fullWidth
                                            items={Data.OrderDetails_ExteriorFinish}
                                            label='Finish'
                                            name='finish'
                                            onSelect={(item) => {
                                                dispatchRoomUpdate({ exteriorFinish: item?.label })
                                            }}
                                        />
                                        <BaseInput
                                            name='color'
                                            label='Color'
                                            value={values.color}
                                            placeholder='Include color name and number if applicable'
                                            fullWidth={true}
                                            onChange={(e) => {
                                                dispatchRoomUpdate({ color: e.target.value })
                                            }}
                                        />
                                        <BaseInput
                                            name='glaze'
                                            label='Glaze'
                                            value={values.glaze}
                                            placeholder='Glaze'
                                            fullWidth={true}
                                            optional={true}
                                            onChange={(e) => {
                                                dispatchRoomUpdate({ glaze: e.target.value })
                                            }}
                                        />
                                        <ComboBox
                                            defaultValue={values.sheen || '20% Satin'}
                                            fullWidth
                                            items={Data.OrderDetails_Sheen}
                                            label='Sheen'
                                            name='sheen'
                                            onSelect={(item) => {
                                                dispatchRoomUpdate({ sheen: item?.label })
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                                <Box height={32} />
                                {/* Group door columns together, then group drawer columns together */}
                                <Stack spacing={2} direction='row' flexWrap='wrap'>
                                    <Stack spacing={1} style={{ flex: 1, minWidth: 200 }}>
                                        <ComboBox
                                            defaultValue={values.baseDoor?.label || ''}
                                            fullWidth
                                            items={Data.OrderDetails_DoorStyles}
                                            label='Door Style'
                                            name='baseDoor'
                                            onSelect={(item) => {
                                                dispatchRoomUpdate({ baseDoor: Data.OrderDetails_DoorStyles.find(style => style.key === item?.key) })
                                            }}
                                        />
                                        <BaseInput
                                            name='base-panel-profile'
                                            label='Panel Profile'
                                            placeholder='Panel #'
                                            fullWidth={true}
                                            onChange={(e) => {
                                                dispatchRoomUpdate({ baseDoor: { ...values.baseDoor!, label: 'Custom', panelProfile: e.target.value } })
                                            }}
                                            value={values.baseDoor?.panelProfile}
                                        />
                                        <BaseInput
                                            name='base-inside-profile'
                                            label='Inside Profile'
                                            placeholder='Inside Panel #'
                                            fullWidth={true}
                                            onChange={(e) => {
                                                dispatchRoomUpdate({ baseDoor: { ...values.baseDoor!, label: 'Custom', insideProfile: e.target.value } })
                                            }}
                                            value={values.baseDoor?.insideProfile}
                                        />
                                        <BaseInput
                                            name='base-outside-profile'
                                            label='Outside Profile'
                                            placeholder='Outside Panel #'
                                            fullWidth={true}
                                            onChange={(e) => {
                                                dispatchRoomUpdate({ baseDoor: { ...values.baseDoor!, label: 'Custom', outsideProfile: e.target.value } })
                                            }}
                                            value={values.baseDoor?.outsideProfile}
                                        />
                                        <BaseInput
                                            name='base-frame-width'
                                            label='Frame Width'
                                            placeholder='Frame Width'
                                            fullWidth={true}
                                            onChange={(e) => {
                                                dispatchRoomUpdate({ baseDoor: { ...values.baseDoor!, label: 'Custom', frameWidth: e.target.value } })
                                            }}
                                            value={values.baseDoor?.frameWidth}
                                        />
                                    </Stack>
                                    <div style={{ height: 'inherit', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0px 0.5em' }}>
                                        <Divider orientation='vertical' />
                                    </div>
                                    <Stack spacing={1} style={{ flex: 1, minWidth: 200 }}>
                                        <ComboBox
                                            defaultValue={values.largeDrawer?.label || ''}
                                            fullWidth
                                            items={Data.OrderDetails_DoorStyles}
                                            label='Large Drawer'
                                            name='largeDrawer'
                                            onSelect={(item) => {
                                                dispatchRoomUpdate({ largeDrawer: Data.OrderDetails_DoorStyles.find(style => style.key === item?.key) })
                                            }}
                                        />
                                        <BaseInput
                                            name='large-drawer-panel-profile'
                                            label='Panel Profile'
                                            placeholder='Panel #'
                                            fullWidth={true}
                                            onChange={(e) => {
                                                dispatchRoomUpdate({ largeDrawer: { ...values.largeDrawer!, label: 'Custom', panelProfile: e.target.value } })
                                            }}
                                            value={values.largeDrawer?.panelProfile}
                                        />
                                        <BaseInput
                                            name='large-drawer-inside-profile'
                                            label='Inside Profile'
                                            placeholder='Inside Panel #'
                                            fullWidth={true}
                                            onChange={(e) => {
                                                dispatchRoomUpdate({ largeDrawer: { ...values.largeDrawer!, label: 'Custom', insideProfile: e.target.value } })
                                            }}
                                            value={values.largeDrawer?.insideProfile}
                                        />
                                        <BaseInput
                                            name='large-drawer-outside-profile'
                                            label='Outside Profile'
                                            placeholder='Outside Panel #'
                                            fullWidth={true}
                                            onChange={(e) => {
                                                dispatchRoomUpdate({ largeDrawer: { ...values.largeDrawer!, label: 'Custom', outsideProfile: e.target.value } })
                                            }}
                                            value={values.largeDrawer?.outsideProfile}
                                        />
                                        <BaseInput
                                            name='large-drawer-frame-width'
                                            label='Frame Width'
                                            placeholder='Frame Width'
                                            fullWidth={true}
                                            onChange={(e) => {
                                                dispatchRoomUpdate({ largeDrawer: { ...values.largeDrawer!, label: 'Custom', frameWidth: e.target.value } })
                                            }}
                                            value={values.largeDrawer?.frameWidth}
                                        />
                                    </Stack>
                                    <div style={{ height: 'inherit', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        <CopyStyleButton onClick={() => dispatchRoomUpdate({ smallDrawer: values.largeDrawer })} />
                                    </div>
                                    <Stack spacing={1} style={{ flex: 1, minWidth: 200 }}>
                                        <ComboBox
                                            defaultValue={values.smallDrawer?.label || ''}
                                            fullWidth
                                            items={Data.OrderDetails_DoorStyles}
                                            label='Small Drawer'
                                            name='smallDrawer'
                                            onSelect={(item) => {
                                                dispatchRoomUpdate({ smallDrawer: Data.OrderDetails_DoorStyles.find(style => style.key === item?.key) })
                                            }}
                                        />
                                        <BaseInput
                                            name='small-drawer-panel-profile'
                                            label='Panel Profile'
                                            placeholder='Panel #'
                                            fullWidth={true}
                                            onChange={(e) => {
                                                dispatchRoomUpdate({ smallDrawer: { ...values.smallDrawer!, label: 'Custom', panelProfile: e.target.value } })
                                            }}
                                            value={values.smallDrawer?.panelProfile}
                                        />
                                        <BaseInput
                                            name='small-drawer-inside-profile'
                                            label='Inside Profile'
                                            placeholder='Inside Panel #'
                                            fullWidth={true}
                                            onChange={(e) => {
                                                dispatchRoomUpdate({ smallDrawer: { ...values.smallDrawer!, label: 'Custom', insideProfile: e.target.value } })
                                            }}
                                            value={values.smallDrawer?.insideProfile}
                                        />
                                        <BaseInput
                                            name='small-drawer-outside-profile'
                                            label='Outside Profile'
                                            placeholder='Outside Panel #'
                                            fullWidth={true}
                                            onChange={(e) => {
                                                dispatchRoomUpdate({ smallDrawer: { ...values.smallDrawer!, label: 'Custom', outsideProfile: e.target.value } })
                                            }}
                                            value={values.smallDrawer?.outsideProfile}
                                        />
                                        <BaseInput
                                            name='small-drawer-frame-width'
                                            label='Frame Width'
                                            placeholder='Frame Width'
                                            fullWidth={true}
                                            onChange={(e) => {
                                                dispatchRoomUpdate({ smallDrawer: { ...values.smallDrawer!, label: 'Custom', frameWidth: e.target.value } })
                                            }}
                                            value={values.smallDrawer?.frameWidth}
                                        />
                                    </Stack>
                                </Stack>
                                <Box height={32} />
                                <FloatingActionBar>
                                    <Stack spacing={2} direction='row'>
                                        <BaseButton text='ADD NEW ROOM' variant='secondary' rightIcon='add' rounded onClick={() => props.onRoomAdded()} />
                                        <BaseButton text={props.mode === 'create' ? 'ADD CABINETS' : 'EDIT CABINETS'} rightIcon='arrowRight' rounded variant='secondary' onClick={() => {
                                            if (!values.lineItems || values.lineItems?.length === 0) {
                                                dispatchRoomUpdate({ ...values, lineItems: [newCabinet() as LineItem] })
                                            }
                                            setState(UIState.Cabinets)
                                        }} />
                                        <BaseButton text='DELETE ROOM' rightIcon='trash' variant='delete' rounded onClick={() => props.deleteRoom()} />
                                        <BaseButton
                                            text={props.mode === 'create' ? 'SAVE DRAFT' : 'SAVE'} 
                                            variant='tertiary'
                                            rightIcon='save'
                                            rounded
                                            onClick={async () => {
                                                setLoading(true)
                                                await syncDraftOrderWithServer()
                                                setLoading(false)
                                            }}
                                            loading={isLoading} />
                                        <BaseButton text='REVIEW ITEMS' rightIcon='checklist' rounded onClick={() => setDetailDrawerOpen(true)} />
                                        {props.mode === 'edit' && (
                                            <>
                                                <BaseButton text='SUBMIT' rightIcon='arrowRight' rounded onClick={submitOrderUpdates} loading={isLoading} />
                                            </>
                                        )}
                                    </Stack>
                                </FloatingActionBar>
                            </>
                        )}
                        {state === UIState.Cabinets && (
                            <Stack spacing={2}>
                                {!!values.lineItems && values.lineItems.map((item: LineItem, index: number) => (
                                    <Box key={item.key}>
                                        <CabinetForm
                                            key={item.key}
                                            lineItem={item}
                                            remove={(el) => {
                                                let items: LineItem[] = JSON.parse(JSON.stringify(props.room?.lineItems || []))
                                                dispatchRoomUpdate({ lineItems: items.filter(lineItem => lineItem.key !== el.key) })
                                            }}
                                            room={props.room}
                                        />
                                        {index < values.lineItems!.length - 1 && (
                                            <Divider style={{ backgroundColor: 'var(--app-secondary)', marginTop: '0.5em', marginBottom: '1em' }} />
                                        )}
                                    </Box>
                                ))}
                                <FloatingActionBar>
                                    <Stack spacing={2} direction='row'>
                                        <BaseButton text='EDIT ROOM' leftIcon='arrowLeft' rounded variant='primary' onClick={() => setState(UIState.RoomInfo)} />
                                        <BaseButton
                                            text='ADD CABINET'
                                            variant='secondary'
                                            rightIcon='add'
                                            rounded
                                            onClick={() => {
                                                let lineItems = JSON.parse(JSON.stringify(values.lineItems || []))
                                                lineItems.push(newCabinet() as LineItem)
                                                dispatchRoomUpdate({ lineItems })
                                            }}
                                        />
                                        <BaseButton 
                                            text={props.mode === 'create' ? 'SAVE DRAFT' : 'SAVE'} 
                                            variant='tertiary'
                                            rightIcon='save' 
                                            rounded 
                                            onClick={syncDraftOrderWithServer} />
                                        <BaseButton text='REVIEW ITEMS' rightIcon='checklist' rounded onClick={() => setDetailDrawerOpen(true)} />
                                        {props.mode === 'edit' && (
                                            <>
                                                <BaseButton text='SUBMIT' rightIcon='arrowRight' rounded onClick={submitOrderUpdates} loading={isLoading} />
                                            </>
                                        )}
                                    </Stack>
                                </FloatingActionBar>
                            </Stack>
                        )}
                    </form>
                )}
            </Formik>
            <Drawer
                anchor='right'
                onClose={() => setDetailDrawerOpen(false)}
                open={isDetailDrawerOpen}
                PaperProps={{
                    style: {
                        width: '40vw',
                        minWidth: '500px',
                    }
                }}
            >
                <div className='ui-row' style={{ margin: '1em 0px', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className='column' style={{ maxWidth: '80%' }}>
                        <h5 style={{ fontSize: '1.2em', fontWeight: 600, color: 'var(--app-primary)' }}>Room and Cabinet Details</h5>
                    </div>
                    <BaseButton text='Close' leftIcon='x' onClick={() => setDetailDrawerOpen(false)} style={{ height: 32, padding: 8 }} />
                </div>
                <div style={{ padding: '0px 1em' }}>
                    <RoomsReview allowEdits={true} rooms={draftOrder?.rooms || []} />
                </div>
            </Drawer>
        </div>
    )
}