export enum OrderStatus {
    Approved = 'approved',
    Archived = 'archived',
    BackOrder = 'backorder',
    Cancelled = 'cancelled',
    Delivered = 'delivered',
    Open = 'open',
    ReadyForReview = 'readyForReview',
    Reviewed = 'reviewed',
    Quoting = 'quoting',
    Submitted = 'submitted',
    Shipped = 'shipped',
}

export enum UserRoles {
    Admin = 1,
    CustomerService = 2,
    Accountant = 3,
    FieldRep = 4,
    Dealer = 5,
    Designer = 6,
    LocationManager = 7,
    LocationBillingContact = 8,
}

export enum UserStatus {
    Active = 1,
    Suspended = 2,
    Deleted = 3,
}