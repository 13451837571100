import { Paper } from '@mui/material'
import { ReactChildren } from 'react'

const FloatingActionBar = ({children}: {children: JSX.Element}) => {
    return (
        <Paper elevation={8} sx={{ padding: 1, position: 'fixed', bottom: 8, right: 8, backgroundColor: 'white', border: '1px solid var(--app-secondary)', borderRadius: '8px' }}>
            {children}
        </Paper>
    )
}

export default FloatingActionBar