import { FormatBoldRounded, FormatColorTextRounded, FormatItalicRounded, FormatListBulletedRounded, FormatStrikethroughRounded, FormatUnderlinedRounded, RedoRounded, SvgIconComponent, UndoRounded } from '@mui/icons-material'
import {IconButton, Paper, Stack, Typography } from '@mui/material'
import { Transaction } from '@tiptap/pm/state'
import { EditorProvider, FloatingMenu, BubbleMenu, useCurrentEditor, Editor as TipTapEditor } from '@tiptap/react'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import Color from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'

interface RichTextEditorProps {
    content: string
    onChange: (val: string) => void
}

const EditorControl = ({ active, disabled, Icon, iconColor, label, onClick }: { active: boolean, disabled: boolean, Icon: SvgIconComponent, iconColor?: string, label: string, onClick: () => void }) => {
    return (
        <IconButton disabled={disabled} onClick={onClick} sx={{ borderRadius: 1, backgroundColor: 'var(--ui-lightgrey)' }}>
            <Icon fontSize='small' htmlColor={disabled ? 'var(--ui-dark-grey)' : iconColor || 'var(--app-secondary)'} />
        </IconButton>
    )
}

const MenuBar = () => {
    const { editor } = useCurrentEditor()

    if (!editor) {
        return null
    }

    return (
        <Paper sx={{padding: 0.5, boxShadow: 'var(--floating-drop-shadow)'}}>
            <Stack direction='row' flexWrap='wrap' spacing={0.5}>
                <EditorControl
                    active={editor.isActive('bold')}
                    disabled={!editor.can().chain().focus().toggleBold().run()}
                    Icon={FormatBoldRounded}
                    label='Bold'
                    onClick={() => editor.chain().focus().toggleBold().run()}
                />
                <EditorControl
                    active={editor.isActive('italic')}
                    disabled={!editor.can().chain().focus().toggleItalic().run()}
                    Icon={FormatItalicRounded}
                    label='Italic'
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                />
                <EditorControl
                    active={editor.isActive('underline')}
                    disabled={!editor.can().chain().focus().toggleUnderline().run()}
                    Icon={FormatUnderlinedRounded}
                    label='Underline'
                    onClick={() => editor.chain().focus().toggleUnderline().run()}
                />
                <EditorControl
                    active={editor.isActive('strike')}
                    disabled={!editor.can().chain().focus().toggleStrike().run()}
                    Icon={FormatStrikethroughRounded}
                    label='Strikethrough'
                    onClick={() => editor.chain().focus().toggleStrike().run()}
                />
                {/* <button
                    onClick={() => editor.chain().focus().setColor('#958DF1').run()}
                    className={editor.isActive('textStyle', { color: '#958DF1' }) ? 'is-active' : ''}
                >
                    Purple
                </button> */}
                <EditorControl
                    active={true}
                    disabled={!editor.can().chain().focus().setColor('#ff0000').run()}
                    Icon={FormatColorTextRounded}
                    iconColor='#ff0000'
                    label='Red Text'
                    onClick={() => editor.chain().focus().setColor('#ff0000').run()}
                />
                <EditorControl
                    active={true}
                    disabled={!editor.can().chain().focus().setColor('#0000ff').run()}
                    Icon={FormatColorTextRounded}
                    iconColor='#0000ff'
                    label='Blue Text'
                    onClick={() => editor.chain().focus().setColor('#0000ff').run()}
                />
                <EditorControl
                    active={true}
                    disabled={!editor.can().chain().focus().setColor('var(--app-text-dark)').run()}
                    Icon={FormatColorTextRounded}
                    iconColor='var(--app-text-dark)'
                    label='Default Text Color'
                    onClick={() => editor.chain().focus().setColor('var(--app-text-dark)').run()}
                />
                <EditorControl
                    active={editor.isActive('bulletList')}
                    disabled={!editor.can().chain().focus().toggleBulletList().run()}
                    Icon={FormatListBulletedRounded}
                    label='List'
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                />
                <EditorControl
                    active={true}
                    disabled={!editor.can().chain().focus().undo().run()}
                    Icon={UndoRounded}
                    label='Undo'
                    onClick={() => editor.chain().focus().undo().run()}
                />
                <EditorControl
                    active={true}
                    disabled={!editor.can().chain().focus().redo().run()}
                    Icon={RedoRounded}
                    label='Redo'
                    onClick={() => editor.chain().focus().redo().run()}
                />
                {/* <button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
                    Clear marks
                </button> */}
                {/* <button onClick={() => editor.chain().focus().clearNodes().run()}>
                    Clear nodes
                </button> */}
                {/* <button
                    onClick={() => editor.chain().focus().setParagraph().run()}
                    className={editor.isActive('paragraph') ? 'is-active' : ''}
                >
                    Paragraph
                </button> */}
                {/* <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                    className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                >
                    H1
                </button> */}
                {/* <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                    className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
                >
                    H2
                </button> */}
                {/* <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                    className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
                >
                    H3
                </button> */}
                {/* <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
                    className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
                >
                    H4
                </button> */}
                {/* <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
                    className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
                >
                    H5
                </button> */}
                {/* <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
                    className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
                >
                    H6
                </button> */}
                {/* <button
                    onClick={() => editor.chain().focus().toggleOrderedList().run()}
                    className={editor.isActive('orderedList') ? 'is-active' : ''}
                >
                    Ordered list
                </button> */}
                {/* <button
                    onClick={() => editor.chain().focus().toggleCodeBlock().run()}
                    className={editor.isActive('codeBlock') ? 'is-active' : ''}
                >
                    Code block
                </button> */}
                {/* <button
                    onClick={() => editor.chain().focus().toggleBlockquote().run()}
                    className={editor.isActive('blockquote') ? 'is-active' : ''}
                >
                    Blockquote
                </button> */}
                {/* <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
                    Horizontal rule
                </button> */}
                {/* <button onClick={() => editor.chain().focus().setHardBreak().run()}>
                    Hard break
                </button> */}
            </Stack>
        </Paper>
    )
}

const extensions = [
    Color.configure({
        types: [TextStyle.name]
    }),
    StarterKit.configure({
        bulletList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
    }),
    TextStyle,
    Underline,
]

const Editor = (props: RichTextEditorProps) => {
    const handleChange = (event: { editor: TipTapEditor, transaction: Transaction }) => {
        let val = event.editor.getHTML()
        props.onChange(val)
    }
    return (
        <EditorProvider
            // slotBefore={<MenuBar />}
            slotAfter={<MenuBar />}
            extensions={extensions}
            content={props.content}
            editorContainerProps={{
                style: {
                    border: '1px solid var(--ui-light-blue)',
                    borderRadius: '8px',
                    minHeight: '8em',
                    outline: 'unset',
                },
                inputMode: 'text',
            }}
            editorProps={{
                attributes: {
                    // class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none',
                    style: 'outline: unset; min-height: 4em; padding: 0.5em;',
                }
            }}
            onBlur={handleChange}
        >
            {/* <BubbleMenu editor={null}>
                <MenuBar />
            </BubbleMenu> */}
        </EditorProvider>
    )
}

export default Editor