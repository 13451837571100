import { useEffect, useRef, useState } from 'react'
import { DateTime } from 'luxon'
import { useAppSelector } from '../../store/hooks'
import * as Store from '../../store/slices'
import { Message, S3FileInfo } from '../../types'
import { ApiUtility, Endpoints, StorageUtility } from '../../utilities'
import { CircularProgress, IconButton } from '@mui/material'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'

// TODO: Implement state for icons: Download, then stop while downloading, then done when done downloading; error icon if unavailable
// import DownloadDoneRoundedIcon from '@mui/icons-material/DownloadDoneRounded'
// import StopCircleRoundedIcon from '@mui/icons-material/StopCircleRounded'

type FileRowProps = {
    message: Message
    onClick: () => void
}

type MessageContentProps = {
    message: Message
    mine: boolean
}

const ImageActionOverlay = (props: {onClick: () => void}): JSX.Element => {
    return (
        <div style={{ position: 'absolute', top: 0, left: 0, backgroundColor: '#000000', zIndex: 10, height: '100%', width: '100%', opacity: 0.6, borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <IconButton style={{ zIndex: 11, backgroundColor: 'var(--ui-sky-blue)' }} onClick={props.onClick}>
                <DownloadRoundedIcon fontSize='small' style={{ color: '#000', opacity: 1 }} />
            </IconButton>
        </div>
    )
}

const ImagePreview = ({ src }: { src: string }): JSX.Element => {
    const [showOverlay, toggleOverlay] = useState<boolean>(false)

    const downloadFile = (url: string) => {
        window.open(url, '_blank')
    }

    return (
        <div
            onMouseEnter={() => toggleOverlay(true)}
            onMouseLeave={() => toggleOverlay(false)}
            style={{ position: 'relative', maxHeight: '120px', maxWidth: '120px' }}
        >
            <img src={src} style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '5px', zIndex: 9 }} />
            {/* Overlay */}
            {showOverlay && (
                <ImageActionOverlay onClick={() => downloadFile(src)} />
            )}
        </div>
    )
}

const FileContent = ({ message, mine }: MessageContentProps): JSX.Element => {
    const contentRef = useRef<HTMLDivElement>(null)
    const [loading, setLoading] = useState(true)
    const [files, setFiles] = useState<S3FileInfo[]>([])
    const imageFiles = files.filter(el => StorageUtility.IsImageFile(el))
    const nonImageFiles = files.filter(el => !StorageUtility.IsImageFile(el))

    const isElementOnScreen = (element: HTMLDivElement): boolean => {
        let rect = element.getBoundingClientRect()
        var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
        return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    }

    const isMessageOnScreen = (message: Message): boolean => {
        return !!contentRef.current && isElementOnScreen(contentRef.current)
    }

    const getFileList = async () => {
        if (!message.uuid) {
            return
        }

        // Get list of uploads for message
        let response = await ApiUtility.Get(Endpoints.MessageUploads + '?message=' + message.uuid)
        if (!response.success) {
            setLoading(false)
            return
        }

        // Update master list
        setFiles(response.data)

        // Turn off loading indicator
        setLoading(false)
    }

    const downloadFile = (url: string) => {
        window.open(url, '_blank')
    }

    const downloadAllFiles = () => {
        // NOTE: Chrome seems to be ignoring subsequent requests to open urls
        for (let i = 0; i < files.length; i++) {
            setTimeout(() => {
                downloadFile(files[i].url)
            }, i * 2000)
        }
    }

    useEffect(() => {
        if (isMessageOnScreen(message) && loading) {
            getFileList()
        }
    }, [contentRef.current, contentRef.current?.getBoundingClientRect().bottom])

    return (
        <div ref={contentRef} className={`message ${mine ? 'my-message' : ''}`}>
            {/* IMAGE FILE PREVIEWS */}
            {imageFiles.length > 0 && (
                <div className='grid-container'>
                    <div className='grid' style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
                        {imageFiles.map(el => (
                            <ImagePreview key={el.id} src={el.url} />
                        ))}
                    </div>
                </div>
            )}
            {imageFiles.length > 0 && nonImageFiles.length > 0 && (
                <div style={{ marginTop: '1em', padding: '0 0.5em' }}>
                    <p className='subtle noselect' style={{ fontStyle: 'normal', textDecoration: 'underline' }}>Attachments</p>
                </div>
            )}
            {/* NON IMAGE FILES */}
            {nonImageFiles.length > 0 && (
                <div>
                    {nonImageFiles.map(el => (
                        <div key={el.id} className='noselect chat-file-item'>
                            <p className='file-name' style={{ fontSize: '0.75em' }}>{el.name}</p>
                            <IconButton onClick={() => downloadFile(el.url)}>
                                <DownloadRoundedIcon fontSize='small' />
                            </IconButton>
                        </div>
                    ))}
                </div>
            )}
            {/* {files.length > 1 && (
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8px' }}>
                    <p className='subtle noselect' style={{ fontStyle: 'normal', textDecoration: 'underline', cursor: 'pointer' }} onClick={downloadAllFiles}>Download All</p>
                </div>
            )} */}
            {loading && (
                <CircularProgress size='2em' style={{ color: 'var(--app-secondary)' }} />
            )}
        </div>
    )
}

const TextContent = ({ message, mine }: MessageContentProps): JSX.Element => {
    return (
        <div className={`message ${mine ? 'my-message' : ''}`}>
            {message.body}
        </div>
    )
}

export default function MessageRow(props: FileRowProps) {
    const currentUser = useAppSelector(Store.getCurrentUser)
    const mine = currentUser?.authId === props.message.createdBy
    const sent = DateTime.fromISO(props.message.created)

    const getSender = (): string => {
        const sentDisplay = sent.toISODate() === DateTime.now().toISODate() ? sent.toFormat('h:mm a') : sent.toLocaleString(DateTime.DATETIME_MED)

        let sender = props.message.sender || 'User'
        if (props.message.senderTitle) {
            sender += ' • ' + props.message.senderTitle
        }
        sender += ' • ' + sentDisplay

        return sender
    }

    return (
        <div className='message-row' style={{ justifyContent: mine ? 'flex-end' : 'flex-start' }}>
            <div className='column' style={{ alignItems: mine ? 'flex-end' : 'flex-start', maxWidth: '80%' }}>
                {props.message.type === 'text'
                    ? <TextContent mine={mine} message={props.message} />
                    : <FileContent mine={mine} message={props.message} />
                }
                <p className='subtle' style={{textAlign: mine ? 'right' : 'left', margin: '0px 1rem',}}>{getSender()}</p>
            </div>
        </div>
    )
}